<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
      >
        <div>
          <td style="vertical-align: middle;">
            <logo
              style="height: 50px; width: auto; margin-top: 0.15rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text text-primary"
              style="margin-top: 7px; margin-left: 0.5rem; text-align: center;"
            >
              {{ appName }}
            </h2>
          </td>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            :alt="$t('Login')"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h3"
          >
            {{ $t('MessageLogin.Title', { appname: appName }) }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('MessageLogin.Text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="$t('Email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    ref="login_email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="user@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('Password') }}</label>
                  <b-link
                    :to="{name:'auth-forgot-password'}"
                    tabindex="-1"
                  >
                    <small>{{ $t('Forgot Password') }}?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('Remember Me') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('Sign in') }}
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>{{ $t('New on our platform') }}</span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;{{ $t('Create an account') }}</span>
            </b-link>
          </b-card-text> -->

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Logo from '@/@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import {
  onUnmounted,
} from '@vue/composition-api'
import storeLineOa from '@/views/line/oa/storeLineOa'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appName } = $themeConfig.app

    if (!store.hasModule('login-line-oa')) store.registerModule('login-line-oa', storeLineOa)
    onUnmounted(() => {
      if (store.hasModule('login-line-oa')) store.unregisterModule('login-line-oa')
    })

    return {
      appName,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      skin: store.state.appConfig.layout.skin,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localize(this.$i18n.locale)
    this.userEmail = ''
    this.password = ''
    // this.$refs.login_email.focus()
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              // console.log(response.data)
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              // console.log(userData)
              localStorage.setItem(`${$themeConfig.app.commonSession}-user-data`, JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // // ? This is just for demo purpose as well.
              // // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // get line oa if total = 1 to auto select
              store.dispatch('login-line-oa/getListLineOa', {
                q: '',
                sortBy: '',
                page: '',
                perPage: '',
                userId: userData.id,
              })
                .then(lineoa => {
                  const { list, total } = lineoa.data
                  if (total === 1) {
                    const lineOa = JSON.stringify(list[0])
                    store.commit('lineapi/UPDATE_LINE_OA', lineOa)
                    this.$router.push({ name: 'home' })
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `${this.$i18n.t('Welcome')} ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `${this.$i18n.t('You have successfully logged in as')} ${userData.role}`,
                          },
                        })
                      })
                  } else {
                    this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `${this.$i18n.t('Welcome')} ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `${this.$i18n.t('You have successfully logged in as')} ${userData.role}`,
                          },
                        })
                      })
                  }
                })
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                // console.log(response.data.error)

                // const msg_error = response.data.error

                const error1 = {
                  email: [this.$i18n.t('Email or Password is Invalid')],
                }

                this.$refs.loginForm.setErrors(error1)
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
